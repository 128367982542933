<template>
  <el-container>
    <!-- <div class="alert-info" v-if="!isChrome">
      <span>你目前使用的浏览器可能无法得到最佳浏览效果，建议使用最新版Chrom浏览器</span>
      <a href="https://www.google.cn/intl/zh-CN/chrome/" target="_blank">点击下载</a>
    </div> -->
    <el-main class="main">
      <span class="back" @click="back">
        <span class="el-icon-back"></span>返回我的课程
      </span>
      <!-- <hr /> -->
      <!-- firstTask：{{firstTask}}
      <hr />
      previousStartData：{{previousStartData}} -->
      <el-row class="box-line top-box">
        <h3 class="lesson-title" v-text="courseDetails.nick"></h3>
        <div style="position: absolute;right:40px;top:10px;" >
          <el-button class="el-icon-refresh" style="font-size: 20px" type="text" @click.stop="initData"></el-button>
        </div>
        <el-row style="height: 150px; margin-top: 30px">
          <el-col :span="6" class="col-item">
            <el-row class="circle-con">
              <el-progress
                type="circle"
                :percentage="
                  courseDetails.completePercent == null
                    ? 0
                    : Math.round(courseDetails.completePercent)
                "
                :color="'#F5C319'"
              ></el-progress>
              <span class="planTit">学习进度</span>
            </el-row>
          </el-col>
          <el-col :span="1" class="col-item"></el-col>
          <el-col :span="12" class="col-text">
            <el-row style="width: 100%">
              <el-col :span="10">
                <span class="iconfont icon-yiwancheng"></span>
                <span>
                  <span class="f14">已完成任务</span>
                  <br/>
                  <font v-text="taskFinishNum" class="finish-tit"></font> /
                  <font v-text="courseDetails.taskCount"></font>
                </span>
              </el-col>
              <el-col
                :span="14"
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                <template v-if="taskFinishNum !== taskDisparkNum">
                  <span class="iconfont icon-yongshi"></span>
                  <span>
                    <span class="f14">正在学习</span>
                    <br/>
                    <span style="color:#000;margin-right:8px">第{{ firstTask.parentSort }}课</span>
                    <span style="color:#000;margin-right:8px">第{{ firstTask.sort }}节</span>
                    <span style="color:#000;margin-right:8px">{{ firstTask.title }}</span>
                  </span>
                </template>
                <template v-else>
                  <span class="iconfont icon-yiwancheng"></span>
                  <span>已完成全部任务</span>
                </template>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="1" class="col-item"></el-col>
          <el-col :span="4" class="col-item">
            <template v-if="taskFinishNum === taskDisparkNum">
              <el-tooltip
                effect="dark"
                content="您已完成全部任务，可在课程目录中选任务复习哦"
                placement="top"
              >
                <el-button type="primary" size="medium">已完成</el-button>
              </el-tooltip>
            </template>
            <template v-else>
              <el-button
                type="primary"
                @click="continueStu"
                style="color:#fff"
              >继续学习
              </el-button
              >
            </template>
          </el-col>
        </el-row>
      </el-row>
      <!-- 我的课程 -->
      <!-- taskData: {{taskData}} -->
      <!-- <el-row :gutter="24"> -->
      <!-- <el-col :span="24"> -->
      <div class="box-line my-lesson">
        <span class="lesson-ml"> <i class="catalog-tit"></i>课程目录 </span>
        <el-row class="lesson-num">
          <span>
            总课次：
            <font v-text="courseDetails.unitCount"></font>
          </span>
          <span>
            总任务数：
            <font v-text="courseDetails.taskCount"></font>
          </span>
        </el-row>
        <div v-if="dataList.length === 1">
          <el-collapse
            v-for="(item, index) in taskData"
            :key="index"
            v-model="activeName"
          >
            <!-- {{item}} -->
            <el-popover placement="right" width="250" trigger="click">
              <div>请选择需要查看的补课视频</div>
              <ul style="height: 300px; margin-top: 10px; overflow-y: auto">
                <li
                  class="clearfix lesson-item"
                  :key="index"
                  v-for="(value, index) of item.attachmentUserList"
                >
                  <span class="fl" style="line-height: 29px">{{
                      value.attachmentTitle
                    }}</span>
                  <el-button
                    @click.stop="watchVideo(value)"
                    class="fr"
                    size="mini"
                    type="primary"
                  >查看视频
                  </el-button
                  >
                </li>
              </ul>
              <a
                slot="reference"
                class="lesson-address"
                href="javascript:void(0)"
                v-if="item.attachmentUserList.length"
              >
                观看补课视频
                <span class="iconfont icon-bofang"></span>
              </a>
              <!-- <el-button slot="reference">hover 激活</el-button> -->
            </el-popover>
            <el-collapse-item
              :title="`第${index + 1}课：${item.title}`"
              :name="index"
            >
              <ul
                v-for="(activity, index) in item.taskList"
                :key="index"
                class="task-list"
              >
                <template v-if="activity.status === '已启用'">
                  <li class="task-item">
                    <span class="task-tit" @click="previewTask(activity)">
                      <span class="task-dy">任务{{ index + 1 }}</span>
                      {{ activity.title }}
                    </span>
                    <span
                      class="task-right"
                      v-if="!activity.startTime"
                      style="color: #c2c2c2"
                    >未学习</span
                    >
                    <span
                      class="task-right"
                      v-else-if="activity.startTime && !activity.finishTime"
                      style="color: #f5c319"
                    >已学习</span
                    >
                    <span class="task-right" v-else style="color: #90dc61"
                    >已完成</span
                    >
                    <el-popover
                      class="task-right task-cursor"
                      placement="left"
                      trigger="click"
                      v-if="activity.videoAttachments.length != 0"
                      width="238"
                    >
                      <div>请选择需要查看的补课视频</div>
                      <ul class="stuList clearfix">
                        <li
                          :key="index"
                          v-for="(value, index) of activity.videoAttachments"
                        >
                          <span class="stupreview-tit">{{ value.title }}</span>
                          <el-button
                            @click.stop="previewVideo(value)"
                            class="stu-switch"
                            size="small"
                            type="primary"
                          >查看视频
                          </el-button
                          >
                        </li>
                      </ul>
                      <el-button
                        plain
                        size="small"
                        slot="reference"
                        style="margin-left: 10px"
                        type="primary"
                      >补课视频
                      </el-button
                      >
                    </el-popover>
                  </li>
                </template>
                <template v-else>
                  <li class="task-item">
                    <span class="task-tit" style="cursor: default">
                      <span class="task-dy">任务{{ index + 1 }}</span>
                      {{ activity.title }}
                    </span>
                    <span class="task-right" style="color: #c2c2c2">敬请期待</span>
                  </li>
                </template>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div v-else>
          <el-collapse
            v-for="(value, index) in dataList"
            :key="index"
            v-model="activeName"
          >
            <el-collapse-item>
              <template slot="title">
                <span style="font-size: 16px" v-if="value.title === ''"
                >默认分组</span
                >
                <span style="font-size: 16px" v-else>{{ value.title }}</span>
              </template>
              <el-collapse
                style="margin-left: 30px"
                v-for="(item, index) in value.data"
                :key="index"
                v-model="activeName"
              >
                <!-- {{item}} -->
                <el-popover placement="right" width="250" trigger="click">
                  <div>请选择需要查看的补课视频</div>
                  <ul style="height: 300px; margin-top: 10px; overflow-y: auto">
                    <li
                      class="clearfix lesson-item"
                      :key="index"
                      v-for="(value, index) of item.attachmentUserList"
                    >
                      <span class="fl" style="line-height: 29px">{{
                          value.attachmentTitle
                        }}</span>
                      <el-button
                        @click.stop="watchVideo(value)"
                        class="fr"
                        size="mini"
                        type="primary"
                      >查看视频
                      </el-button
                      >
                    </li>
                  </ul>
                  <a
                    slot="reference"
                    class="lesson-address"
                    href="javascript:void(0)"
                    v-if="item.attachmentUserList.length"
                  >
                    观看补课视频
                    <span class="iconfont icon-bofang"></span>
                  </a>
                  <!-- <el-button slot="reference">hover 激活</el-button> -->
                </el-popover>
                <el-collapse-item>
                  <template slot="title">
                    <span style="font-size: 16px"
                    >第{{ index + 1 }}课<span style="margin-left: 10px">{{
                        item.title
                      }}</span></span
                    >
                  </template>
                  <ul
                    v-for="(activity, index) in item.taskList"
                    :key="index"
                    class="task-list"
                  >
                    <template v-if="activity.status === '已启用'">
                      <li class="task-item">
                        <span class="task-tit" @click="previewTask(activity)">
                          <span class="task-dy">任务{{ index + 1 }}</span>
                          {{ activity.title }}
                        </span>
                        <span
                          class="task-right"
                          v-if="!activity.startTime"
                          style="color: #c2c2c2"
                        >未学习</span
                        >
                        <span
                          class="task-right"
                          v-else-if="activity.startTime && !activity.finishTime"
                          style="color: #f5c319"
                        >已学习</span
                        >
                        <span class="task-right" v-else style="color: #90dc61"
                        >已完成</span
                        >
                        <el-popover
                          class="task-right task-cursor"
                          placement="left"
                          trigger="click"
                          v-if="activity.videoAttachments.length != 0"
                          width="238"
                        >
                          <div>请选择需要查看的补课视频</div>
                          <ul class="stuList clearfix">
                            <li
                              :key="index"
                              v-for="(
                                value, index
                              ) of activity.videoAttachments"
                            >
                              <span
                                class="stupreview-tit"
                                style="font-size: 14px"
                              >{{ value.title }}</span
                              >
                              <el-button
                                @click.stop="previewVideo(value)"
                                class="stu-switch"
                                size="small"
                                type="primary"
                              >查看视频
                              </el-button
                              >
                            </li>
                          </ul>
                          <el-button
                            plain
                            size="small"
                            slot="reference"
                            style="margin-left: 10px"
                            type="primary"
                          >补课视频
                          </el-button
                          >
                        </el-popover>
                      </li>
                    </template>
                    <template v-else>
                      <li class="task-item">
                        <span class="task-tit" style="cursor: default">
                          <span class="task-dy">任务{{ index + 1 }}</span>
                          {{ activity.title }}
                        </span>
                        <!-- <span class="task-type">{{activity.type}}</span> -->
                        <span class="task-right" style="color: #c2c2c2"
                        >敬请期待</span
                        >
                      </li>
                    </template>
                  </ul>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <!-- </el-col> -->
      <!-- </el-row> -->
    </el-main>
    <join-live
      :show="dialogLive"
      :liveId="liveId"
      @close="dialogLive = false"
    ></join-live>
  </el-container>
</template>

<script>
import joinLive from './joinLive'
import { formatTate } from '@/utils/formatDate'
import AgoraRTM from 'agora-rtm-sdk'
import {
  getPreviousStart,
  getCourseInfo,
  getTestMyLesson,
  getListMyLesson,
  getCourseUnit,
} from '@/api/businesscourse/train'

export default {
  name: 'curriculumCatalog',
  components: {
    joinLive,
  },
  watch: {
    $route (to, from) {
      // console.log(to, from)
      // if (to.path === "/curriculumCatalog") {
      //   window.location.reload();
      // }
      // this.$router.push({
      //   path: "/trains/course-in/" + this.$route.params.id,
      //   query: {
      //     id: this.$route.params.id,
      //     subTitle:  this.$route.query.subTitle,
      //   },
      // });
      // this.$router.push({path:to.path})
      this.initData()
    },
  },
  data () {
    return {
      getScore: null,
      dataList: [],
      taskData: [],
      reverse: false,
      lessonId: '',
      courseDetails: {},
      taskFinishNum: 0, //  已完成任务数
      taskDisparkNum: 0, // 已开放的任务数
      firstTask: {}, // 下一个未完成的任务
      taskId: '', // 任务id
      isChrome: '',
      classList: [],
      myClassList: [], // 今日直播
      myAssiantList: [],
      dialogLive: false,
      liveId: '',
      currentTime: null, // 当前时间
      previousStartData: null, // 最后一个开始的任务
      activeName: [],
      integralList: [], // 积分排行榜
      globalClient: null,
      globalChannel: null,
      currentLessonId: '',
      currentteacherName: '',
      currentIndex: -1,
      source: '',
    }
  },

  mounted () {
    // if(sessionStorage.getItem('currentLessonId') !== null && sessionStorage.getItem('currentLessonId') !== undefined ) {
    //   this.currentLessonId = sessionStorage.getItem('currentLessonId')
    //   this.currentteacherName = sessionStorage.getItem('currentteacherName')
    //   this.currentIndex = sessionStorage.getItem('currentIndex')
    //   this.initAgoraToken(this.currentIndex)
    // }
    // let _this = this
    // let hidden, visibilityChange
    // if (typeof document.hidden !== "undefined") {
    //   hidden = "hidden";
    //   visibilityChange = "visibilitychange";
    // } else if (typeof document.mozHidden !== "undefined") {
    //   hidden = "mozHidden";
    //   visibilityChange = "mozvisibilitychange";
    // } else if (typeof document.msHidden !== "undefined") {
    //   hidden = "msHidden";
    //   visibilityChange = "msvisibilitychange";
    // } else if (typeof document.webkitHidden !== "undefined") {
    //   hidden = "webkitHidden";
    //   visibilityChange = "webkitvisibilitychange";
    // }
    //
    // document.addEventListener(visibilityChange, function() {
    //   if(!document[hidden]) {
    //     _this.getAgoraToken(null,_this.currentIndex)
    //   }else {
    //     let visibilityObject =  {
    //       type:'pageVisibility',
    //       pagestate:-1,
    //       pageTitle:'无'
    //     }
    //
    //     _this.$nextTick(()=> {
    //       if(_this.globalChannel !== null) {
    //         _this.globalChannel.sendMessage({ text: JSON.stringify(visibilityObject) }).then(() => {
    //           /* 频道消息发送成功的处理逻辑 */
    //         }).catch(error => {
    //           /* 频道消息发送失败的处理逻辑 */
    //         });
    //       }
    //     })
    //   }
    // }, false);
  },
  created() {
   this.initData()
  },
  methods: {
    initData () {
      this.currentTime = formatTate(new Date())
      this.classList = []
      this.lessonId = this.$route.query.id
      this.getPreviousStart()
      // let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      // this.isChrome = userAgent.indexOf("Chrome") > -1;

      let sysObj = {}
      let browserInfo = navigator.userAgent.toLowerCase()
      let vsMsg;

      (vsMsg = browserInfo.match(/msie ([\d.]+)/))
        ? (sysObj.ie = vsMsg[1])
        : (vsMsg = browserInfo.match(/firefox\/([\d.]+)/))
          ? (sysObj.firefox = vsMsg[1])
          : (vsMsg = browserInfo.match(/chrome\/([\d.]+)/))
            ? (sysObj.chrome = vsMsg[1])
            : (vsMsg = browserInfo.match(/opera.([\d.]+)/))
              ? (sysObj.opera = vsMsg[1])
              : (vsMsg = browserInfo.match(/version\/([\d.]+).*safari/))
                ? (sysObj.safari = vsMsg[1])
                : 0

      if (sysObj.ie) {
        this.isChrome = false
      }
      if (sysObj.firefox) {
        this.isChrome = false
      }
      if (sysObj.chrome) {
        if (Number(sysObj.chrome.substring(0, 2)) < 77) {
          this.isChrome = false
        } else {
          this.isChrome = true
        }
      }
      if (sysObj.opera) {
        this.isChrome = false
      }
      if (sysObj.safari) {
        this.isChrome = false
      }

      if (!this.lessonId) {
      } else {
        this.getCourseInfo()
        this.getCourseUnit()
      }
    },
    // 声网的方法开始
    //初始化rtm
    initAgora (token, appid, index) {
      let that = this
      this.globalClient = AgoraRTM.createInstance(appid)
      this.globalClient.on('ConnectionStateChanged', (newState, reason) => {
        console.log(
          'on connection state changed to ' + newState + ' reason: ' + reason
        )
      })
      this.globalClient
        .login({ token: token, uid: localStorage.getItem('loginName') })
        .then(() => {
          console.log('AgoraRTM client login success')
          this.globalChannel = this.globalClient.createChannel(
            that.currentLessonId
          ) // 此处传入频道 ID

          this.globalChannel
            .join()
            .then(() => {
              if (index !== -1) {
                that.myClassList[index].enterCourseState = '上课中'
              }
              that.$forceUpdate()
              let visibilityObject = {
                type: 'pageVisibility',
                pagestate: 1,
                pageTitle: that.courseDetails.nick,
              }

              that.$nextTick(() => {
                that.globalChannel
                  .sendMessage({ text: JSON.stringify(visibilityObject) })
                  .then(() => {
                    /* 频道消息发送成功的处理逻辑 */
                    console.log('sendMesssage=======>success')
                  })
                  .catch((error) => {
                    console.log('sendMesssage=======>fail')
                    /* 频道消息发送失败的处理逻辑 */
                  })
              })
            })
            .catch((error) => {
              /* 加入频道失败的处理逻辑 */
            })

          this.globalChannel.on('ChannelMessage', ({ text }, senderId) => {
            // text 为收到的频道消息文本，senderId 为发送方的 User ID
            /* 收到频道消息的处理逻辑 */
            let textObject = JSON.parse(text)
            if (
              senderId.toUpperCase() === that.currentteacherName.toUpperCase()
            ) {
              if (
                textObject.pageRtmType === 'openTaskSingle' &&
                textObject.loginName.toUpperCase() ===
                localStorage.getItem('loginName').toUpperCase()
              ) {
                if (textObject.taskStatus !== '已启用') {
                  return false
                }
                this.previewTask(textObject)
              } else if (textObject.pageRtmType === 'openTaskAll') {
                let status = ''
                textObject.taskUserList.forEach((item) => {
                  if (
                    item.loginName.toUpperCase() ===
                    localStorage.getItem('loginName').toUpperCase()
                  ) {
                    status = item.status
                    textObject.minHistoryTime = item.minHistoryTime
                  }
                })
                if (status !== '已启用') {
                  return false
                }
                this.previewTask(textObject)
              } else if (textObject.type === 'queryStudentState') {
                let visibilityObject = {
                  type: 'pageVisibility',
                  pagestate: 1,
                  pageTitle: that.courseDetails.nick,
                }

                that.$nextTick(() => {
                  that.globalChannel
                    .sendMessage({ text: JSON.stringify(visibilityObject) })
                    .then(() => {
                      /* 频道消息发送成功的处理逻辑 */
                      console.log('sendMesssage=======>success')
                    })
                    .catch((error) => {
                      console.log('sendMesssage=======>fail')
                      /* 频道消息发送失败的处理逻辑 */
                    })
                })
              }
            } else if (textObject.type === 'queryStudentState') {
              let visibilityObject = {
                type: 'pageVisibility',
                pagestate: 1,
                pageTitle: that.courseDetails.nick,
              }

              that.$nextTick(() => {
                that.globalChannel
                  .sendMessage({ text: JSON.stringify(visibilityObject) })
                  .then(() => {
                    /* 频道消息发送成功的处理逻辑 */
                    console.log('sendMesssage=======>success')
                  })
                  .catch((error) => {
                    console.log('sendMesssage=======>fail')
                    /* 频道消息发送失败的处理逻辑 */
                  })
              })
            }
          })
        })
        .catch((err) => {
          console.log('AgoraRTM client login failure', err)
        })
    },
    //声网的方法结束;
    // 获取声网的token
    getAgoraToken (row, index) {
      if (
        this.myClassList[index].enterCourseState === '上课中' &&
        row !== null
      ) {
        window.$msg('你已在此互动课堂中', 2)
        return false
      }
      if (this.currentLessonId === '' && row === null) {
        return false
      }
      if (this.globalClient !== null) {
        this.globalClient.logout()
        this.myClassList.forEach((item) => {
          item.enterCourseState = '登录互动课堂'
        })
      }
      if (row !== null) {
        this.currentLessonId = row.id
        this.currentIndex = index

        row.teacherInfoSet.forEach((item) => {
          if (item.role === '主教') {
            this.currentteacherName = item.loginName
          }
        })

        sessionStorage.setItem('currentLessonId', this.currentLessonId)
        sessionStorage.setItem('currentteacherName', this.currentteacherName)
        sessionStorage.setItem('currentIndex', this.currentIndex)
      }

      fGet('/student/live/getAgoraToken', {
        loginName: localStorage.getItem('loginName'),
      })
        .then((response) => {
          if (response.state === 'success') {
            if (
              response.body.appid === null ||
              response.body.rtmToken === null
            ) {
              window.$msg('token值为空，不能登录直播间，请联系管理员', 2)
              return false
            }
            this.initAgora(response.body.rtmToken, response.body.appId, index)
          } else {
            $msg(response.errMsg, 2)
          }
        })
        .catch((err) => {
          $msg('请求失败1', 1)
        })
    },

    initAgoraToken (index) {
      fGet('/student/live/getAgoraToken', {
        loginName: localStorage.getItem('loginName'),
      })
        .then((response) => {
          if (response.state === 'success') {
            if (
              response.body.appid === null ||
              response.body.rtmToken === null
            ) {
              window.$msg('token值为空，不能登录直播间，请联系管理员', 2)
              return false
            }
            this.initAgora(response.body.rtmToken, response.body.appId, index)
          } else {
            $msg(response.errMsg, 2)
          }
        })
        .catch((err) => {
          $msg('请求失败1', 1)
        })
    },
    valIndex (data, index) {
      let disIdx = false
      // console.log(data, index)
      if (data.length < 1) {
        return 0
      } else {
        data.findIndex((item) => {
          if (item.id === this.previousStartData.taskId) {
            // console.log('123', index)
            disIdx = true
            // return Number(index)
          }
        })
        console.log('disIdx', disIdx, index)
        return Boolean(disIdx) ? index : 0
      }
    },

    // 获取最后开始任务的信息
    getPreviousStart () {
      getPreviousStart({
        courseId: this.$route.query.id,
      })
        .then((res) => {
          if (res.state === 'success') {
            this.previousStartData = res.body
            // console.log("previousStartData", this.previousStartData);
          } else {
            $msg(res.errMsg, 2)
          }
        })
        .catch((err) => {
          // this.previousStartData = [];
          // $msg("请求失败2", 1);
        })
    },
    previewVideo (row) {
      this.priviewVideoDetail(row.content)
    },

    joinTestLive (row) {
      this.liveId = row.id
      this.dialogLive = true
    },
    // 进入直播课堂
    joinLive (row) {
      this.liveId = row.classBliveRoom.id
      this.dialogLive = true
    },

    // 计算当前时间 和 排课直播开始时间
    getLiveTime (start, current) {
      let startTime = new Date(start).getTime()
      let currentTime = new Date(current).getTime()
      if (Number(currentTime) >= Number(startTime) - 60 * 60 * 1000) {
        // console.log('进入直播课堂')
        return true
      } else {
        return false
        // console.log('直播未开课')
      }
      // console.log('getLiveTime', start, current)
    },
    //新版的获取补课视频地址
    queryVideo (id, title) {
      fVideoGet('/video/getBjyUrl', {
        fileId: id,
      })
        .then((res) => {
          if (res.code === 0) {
            let routeData = this.$router.resolve({
              path: '/student/videoPlay/',
              query: {
                url: res.data,
                title: title,
              },
            })
            window.open(routeData.href, '_blank')
          } else {
            $msg(res.msg, 2)
          }
        })
        .catch((err) => {
          $msg('请求失败3', 1)
        })
    },
    datefmt (date) {
      let res = ''
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = date.getDate()
      if (day < 10) {
        day = '0' + day
      }
      res = `${year}-${month}-${day}`
      return res
    },
    // 获取课程信息
    getCourseInfo () {
      getCourseInfo({
        id: this.lessonId,
      })
        .then((res) => {
          if (res.state === 'success') {
            this.courseDetails = res.body
            if (res.body.type === '刷题') {
              // this.ranking();
              // this.myScore();
            } else {
              // this.getClassList();
              // this.getTestMyLesson()
              // this.getListMyLesson()
            }
          } else {
            $msg(res.errMsg, 2)
          }
        })
        .catch((err) => {
          $msg('请求失败4', 1)
        })
    },
    getTestMyLesson () {
      getTestMyLesson({
        needCount: true,
        pageNum: 1,
        pageSize: 1000,
        minTime: this.datefmt(new Date()) + ' 00:00:00',
        maxTime: this.datefmt(new Date()) + ' 23:59:59',
        courseId: this.$route.query.id,
      }).then((res) => {
        if (res.state === 'success') {
          this.myAssiantList = res.body.list
        }
      })
    },
    getListMyLesson () {
      getListMyLesson({
        needCount: true,
        pageNum: 1,
        pageSize: 1000,
        minTime: this.datefmt(new Date()) + ' 00:00:00',
        maxTime: this.datefmt(new Date()) + ' 23:59:59',
        courseId: this.$route.query.id,
      }).then((res) => {
        if (res.state === 'success') {
          this.myClassList = res.body.list
          this.myClassList.forEach((item) => {
            item.enterCourseState = '登录互动课堂'
          })
        }
      })
    },
    // 获取积分榜
    // ranking() {
    //   fPost("/student/course/ranking", {
    //     courseId: this.$route.query.id,
    //     pageNum: 1,
    //     pageSize: 10,
    //   })
    //     .then((res) => {
    //       if (res.state === "success") {
    //         this.integralList = res.body.list;
    //       } else {
    //         $msg(res.errMsg, 2);
    //       }
    //     })
    //     .catch((err) => {
    //       $msg("请求失败5", 1);
    //     });
    // },

    // 获取自己积分
    // myScore() {
    //   fGet("/student/course/myScore", {
    //     courseId: this.$route.query.id,
    //   })
    //     .then((res) => {
    //       if (res.state === "success") {
    //         this.getScore = Number(res.body);
    //       } else {
    //         $msg(res.errMsg, 2);
    //       }
    //     })
    //     .catch((err) => {
    //       $msg("请求失败6", 1);
    //     });
    // },

    formatName (name) {
      let newStr
      if (name.length === 2) {
        newStr = name.substr(0, 1) + '*'
      } else if (name.length > 2) {
        let char = ''
        for (let i = 0, len = name.length - 2; i < len; i++) {
          char += '*'
        }
        newStr = name.substr(0, 1) + char + name.substr(-1, 1)
      } else {
        newStr = name
      }

      return newStr
    },

    // 获取某学员所在班级的列表
    // getClassList() {
    //   fPost("/student/class/list", {
    //     pageNum: 1,
    //     pageSize: 1000,
    //     courseId: this.$route.query.id,
    //   }).then((res) => {
    //     if (res.state === "success") {
    //       //  console.log('classList', res)
    //       this.classList = res.body.list;
    //       //  console.log('classList', this.classList)
    //     }
    //   });
    // },

    arryGroupMatch (arr) {
      let map = {}
      for (let i = 0; i < arr.length; i++) {
        let ai = arr[i]
        if (!map[ai.group]) {
          map[ai.group] = [ai]
        } else {
          map[ai.group].push(ai)
        }
      }
      let res = []
      Object.keys(map).forEach((key) => {
        res.push({
          title: key,
          data: map[key],
        })
      })
      return res
    },

    // 获取课节任务列表
    getCourseUnit () {
      getCourseUnit({
        pageNum: 1,
        pageSize: 1000,
        courseId: this.lessonId,
      }).then((res) => {
        if (res.state === 'success') {
          this.dataList = this.arryGroupMatch(res.body.list)
          this.taskData = res.body.list
          this.taskFinishNum = 0
          // 获取第一个未完成的任务
          this.getFirstTask()
          for (let i = 0; i < this.taskData.length; i++) {
            for (let j = 0; j < this.taskData[i].taskList.length; j++) {
              // if (this.taskData[i].taskList[j].status == "已启用") {
              // this.usingTaskList.push((this.taskData[i].taskList[j].id))
              if (this.taskData[i].taskList[j].finishTime) {
                this.taskFinishNum++
              }
              // }
              if (
                Boolean(this.previousStartData) &&
                this.taskData[i].taskList[j].id ===
                this.previousStartData.taskId
              ) {
                this.activeName = [i]
              } else if (
                !Boolean(this.previousStartData) &&
                this.taskData[i].taskList[j].id === this.firstTask.id
              ) {
                this.activeName = [i]
              }
            }
          }
        } else {
          $msg(res.errMsg, 2)
        }
      })
    },

    getFirstTask () {
      for (let i = 0; i < this.taskData.length; i++) {
        for (let j = 0; j < this.taskData[i].taskList.length; j++) {
          if (
            this.taskData[i].taskList[j].status === '已启用' &&
            !this.taskData[i].taskList[j].finishTime
          ) {
            this.firstTask = { ...this.taskData[i].taskList[j], parentSort: this.taskData[i].sort }
            return false
          }
        }
      }
    },

    // 继续学习
    continueStu () {
      let continueData = {}
      this.taskData.forEach((item, index) => {
        this.taskData[index].taskList.forEach((ele, idx) => {
          if (ele.id === this.firstTask.id) {
            continueData = { ...ele }
          }
        })
      })
      let routeData = this.$router.resolve({
        path: '/meredu/previewTask/',
        query: {
          id: this.firstTask.id,
          minHistoryTime: this.firstTask.minHistoryTime,
          lessonId: this.lessonId,
          videoEnable: continueData.videoSwitch,
          type: continueData.type,
        },
      })
      window.open(routeData.href, '_blank')
    },
    // 班级列表
    gotoClassList (row) {
      this.$router.push({
        path: '/student/classlist/',
        query: {
          classID: row.id,
          className: row.name,
        },
      })
    },
    back () {
      this.$router.back()
    },

    // 跳转--任务页面
    previewTask (activity) {
      // if(activity.type === "视频") {
      // this.priviewVideoDetail(activity.sourceId);
      // }else if(activity.type === "图文"){
      // }else if(activity.type === "试题"){
      // }else{
      let routeData = null
      routeData = this.$router.resolve({
        path: '/meredu/previewTask',
        query: {
          id: activity.id,
          minHistoryTime: activity.minHistoryTime,
          lessonId: this.lessonId,
          videoEnable: activity.videoSwitch,
          type: this.courseDetails.type,
          currentLessonId: this.currentLessonId,
          teacherLoginName: this.currentteacherName,
        },
      })
      window.open(routeData.href, '_blank')
      // }
    },
    // 视频预览
    priviewVideoDetail (sourceVideoId) {
      let routes = this.$router.resolve({
        name: 'pvideos',
        query: {
          role: 'management',
          sourceVideoId: sourceVideoId,
        },
      })
      window.open(routes.href, '_blank')
    },
    // 观看视屏
    watchVideo (row) {
      this.priviewVideoDetail(row.attachmentContent)
      // if (row.attachmentContent.indexOf("http") === -1) {
      // this.queryVideo(row.attachmentContent, row.courseUnitTitle);
      // } else {
      // let routeData = this.$router.resolve({
      //   path: "/student/videoPlay/",
      //   query: {
      //     url: row.attachmentContent,
      //     title: row.attachmentTitle,
      //   },
      // });
      // window.open(routeData.href, "_blank");
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.container_con {
  width: 100%;
  min-width: 1200px;
  background: #f4f4f4;
  min-height: 100%;
  height: auto;
  overflow: auto;
}

.stuList {
  display: flex;
  flex-direction: column;
  height: 300px;
  margin-top: 10px;
  overflow-y: auto;

  li {
    margin-top: 8px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .stu-tit {
    display: inline-block;
    padding-left: 10px;
    justify-content: flex-start;
    cursor: pointer;
  }

  .stu-switch {
    float: right;
  }
}

.header {
  width: 100%;
  display: flex;
  padding-left: 15px;
  align-items: center;
  background: #212121;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
}

.main {
  width: 1200px;
  box-sizing: border-box;
  margin: 0 auto 0;
  // position: relative;
  overflow-y: auto;
  height: calc(100vh - 60px);

  .back {
    cursor: pointer;

    &:hover {
      color: #f5c319;
    }

    .el-icon-back {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .hang {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
  }
  .name {
    max-width: 30%;
  }
  .box-line {
    background: #fff;
    padding: 15px;
    // margin-bottom: 20px;
    margin-top: 24px;
  }
  .top-box {
    background: #ffffff;
    .lesson-title {
      font-size: 20px;
      padding-left: 19px;
      font-weight: 600;
      color: #212121;
    }
    .col-text {
      display: flex;
      align-items: center;
      height: 136px;
      span {
        line-height: 30px;
        color: #a4a4a4;
      }
      .icon-yiwancheng {
        font-size: 15px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
      }
      .icon-yongshi {
        font-size: 16px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: top;
      }
      .finish-tit {
        padding-left: 15px;
        color: #fbc50f;
      }
      .being-tit {
        color: #434343;
        padding-left: 15px;
      }
    }
    .col-item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 136px;
      .circle-con {
        position: relative;
        box-sizing: border-box;
        padding-top: 5px;
        width: 136px;
        height: 136px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 18px 2px rgba(41, 94, 64, 0.1);
      }
    }
  }
  .my-lesson {
    padding-bottom: 50px;
    .lesson-ml {
      font-size: 20px;
    }
    .catalog-tit {
      display: inline-block;
      width: 4px;
      height: 18px;
      background: #f4cf13;
      vertical-align: middle;
      margin-right: 15px;
    }
    .lesson-num {
      margin-top: 25px;
      span {
        &:first-child {
          margin-right: 15px;
        }
      }
      font {
        color: #f5c319;
      }
    }
  }
}

.planTit {
  position: absolute;
  top: 35%;
  left: 26%;
}

// 补课地址
.lesson-address {
  cursor: pointer;
  position: absolute;
  top: 16px;
  // right: 50%;
  right: 10%;
  z-index: 9;
  font-size: 15px;
  color: #666666;
  &:hover {
    color: #f5c319;
  }
}

.task-list {
  width: 100%;
  box-sizing: border-box;
  padding-left: 35px;
  .task-item {
    position: relative;
    padding: 15px 0;
    font-size: 15px;
    color: #696969;
    border-bottom: 1px solid #ebebeb;
    padding-left: 10px;
    .task-tit {
      cursor: pointer;
      &:hover {
        color: #f4cf13;
        .task-dy {
          background: #f4cf13;
          color: #fff;
        }
      }
      .task-dy {
        font-size: 12px;
        display: inline-block;
        padding: 1px 10px;
        background: #eeeeee;
        border-radius: 15px;
        margin-right: 20px;
      }
    }
    .task-type {
      font-size: 12px;
      display: inline-block;
      padding: 1px 20px;
      border-radius: 15px;
      margin-left: 35px;
      color: #fff;
      background-color: rgba(128, 0, 128, 1);
    }
    .task-right {
      float: right;
      margin-right: 25px;
    }
  }
}

.lesson-item {
  margin: 10px 0;
}

.continue-stu {
  background: rgba(245, 195, 25, 1);
  border-radius: 18px;
  color: #212121;
}
.classparent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.classitemname {
  font-size: 16px;
  width: 70%;
  word-wrap: break-word;
}
.classitemlist {
  font-size: 14px;
  color: #666666;
  width: 30%;
  cursor: pointer;
  text-align: center;
  border: solid 1px #9c9c9c;
  padding: 3px 6px;
  border-radius: 10px;

  word-wrap: break-word;
}
.noLive {
  padding-left: 10px;
}
/deep/ .el-progress__text {
  top: 60%;
  font-size: 14px;
  font-weight: 600;
  color: #f5c319;
}

/deep/ .el-timeline-item {
  padding: 15px 0;
}
/deep/ .el-timeline {
  margin: 15px 0;
}
/deep/ .el-collapse {
  border: 0;
}
/deep/ .el-collapse-item__header {
  border-bottom: 0;
  // border: 1px solid #aaaaaa;
  border-bottom: 1px solid #ebebeb;
  margin-top: 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-left: 10px;
  font-size: 16px;
  color: #333333;
}
/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}

/deep/ .el-collapse-item__wrap {
  border-bottom: 0;
}

/deep/ .el-collapse,
.el-collapse-item,
.el-timeline-item__content {
  position: relative;
}

/deep/ .el-timeline-item__content {
  cursor: pointer;
}

.alert-info {
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  padding-left: 25px;
  display: flex;
  color: #0d8aee;
  background-color: #e3f2fd;
  align-items: center;
  font-size: 14px;
  span {
    display: inline-block;
    margin-right: 30px;
  }
}

.medal {
  position: absolute;
  right: 16px;
}
</style>
