import $axios from '@/utils/request'

/**
 * 获取培新列表
 * @param {*} data 
 * @returns 
 */
export function getCourseList(data) {
    const url = '/meredu/course/list'
    return $axios.fPost(url, data)
}
/**
 * 获取课程下课节列表
 * @param {*} data 
 * @returns getCourseUnit
 */
export function getCourseUnit(data) {
    const url = '/meredu/courseUnit/list'
    return $axios.fPost(url, data)
}
/**
 * 获取最后开始任务的信息
 * @param {*} data 
 * @returns 
 */
export function getPreviousStart(data) {
    const url = "/meredu/task/previousStart"
    return $axios.fPost(url, data)
}
/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getCourseInfo(data) {
    const url = '/meredu/course/getInfo'
    return $axios.fPost(url, data)
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getListMyLesson(data) {
    const url = "/meredu/lesson/listLesson";
    return $axios.fPost(url, data)
}
/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getTestMyLesson(data) {
    const url = "/meredu/live/listMyTryOnline";
    return $axios.fPost(url, data)
}