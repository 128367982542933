var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c(
        "el-main",
        { staticClass: "main" },
        [
          _c("span", { staticClass: "back", on: { click: _vm.back } }, [
            _c("span", { staticClass: "el-icon-back" }),
            _vm._v("返回我的课程 "),
          ]),
          _c(
            "el-row",
            { staticClass: "box-line top-box" },
            [
              _c("h3", {
                staticClass: "lesson-title",
                domProps: { textContent: _vm._s(_vm.courseDetails.nick) },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    right: "40px",
                    top: "10px",
                  },
                },
                [
                  _c("el-button", {
                    staticClass: "el-icon-refresh",
                    staticStyle: { "font-size": "20px" },
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.initData.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { height: "150px", "margin-top": "30px" } },
                [
                  _c(
                    "el-col",
                    { staticClass: "col-item", attrs: { span: 6 } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "circle-con" },
                        [
                          _c("el-progress", {
                            attrs: {
                              type: "circle",
                              percentage:
                                _vm.courseDetails.completePercent == null
                                  ? 0
                                  : Math.round(
                                      _vm.courseDetails.completePercent
                                    ),
                              color: "#F5C319",
                            },
                          }),
                          _c("span", { staticClass: "planTit" }, [
                            _vm._v("学习进度"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { staticClass: "col-item", attrs: { span: 1 } }),
                  _c(
                    "el-col",
                    { staticClass: "col-text", attrs: { span: 12 } },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { width: "100%" } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", {
                              staticClass: "iconfont icon-yiwancheng",
                            }),
                            _c(
                              "span",
                              [
                                _c("span", { staticClass: "f14" }, [
                                  _vm._v("已完成任务"),
                                ]),
                                _c("br"),
                                _c("font", {
                                  staticClass: "finish-tit",
                                  domProps: {
                                    textContent: _vm._s(_vm.taskFinishNum),
                                  },
                                }),
                                _vm._v(" / "),
                                _c("font", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.courseDetails.taskCount
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                                "white-space": "nowrap",
                              },
                              attrs: { span: 14 },
                            },
                            [
                              _vm.taskFinishNum !== _vm.taskDisparkNum
                                ? [
                                    _c("span", {
                                      staticClass: "iconfont icon-yongshi",
                                    }),
                                    _c("span", [
                                      _c("span", { staticClass: "f14" }, [
                                        _vm._v("正在学习"),
                                      ]),
                                      _c("br"),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#000",
                                            "margin-right": "8px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "第" +
                                              _vm._s(_vm.firstTask.parentSort) +
                                              "课"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#000",
                                            "margin-right": "8px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "第" +
                                              _vm._s(_vm.firstTask.sort) +
                                              "节"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#000",
                                            "margin-right": "8px",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.firstTask.title))]
                                      ),
                                    ]),
                                  ]
                                : [
                                    _c("span", {
                                      staticClass: "iconfont icon-yiwancheng",
                                    }),
                                    _c("span", [_vm._v("已完成全部任务")]),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { staticClass: "col-item", attrs: { span: 1 } }),
                  _c(
                    "el-col",
                    { staticClass: "col-item", attrs: { span: 4 } },
                    [
                      _vm.taskFinishNum === _vm.taskDisparkNum
                        ? [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "您已完成全部任务，可在课程目录中选任务复习哦",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "medium" },
                                  },
                                  [_vm._v("已完成")]
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#fff" },
                                attrs: { type: "primary" },
                                on: { click: _vm.continueStu },
                              },
                              [_vm._v("继续学习 ")]
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box-line my-lesson" },
            [
              _c("span", { staticClass: "lesson-ml" }, [
                _c("i", { staticClass: "catalog-tit" }),
                _vm._v("课程目录 "),
              ]),
              _c("el-row", { staticClass: "lesson-num" }, [
                _c(
                  "span",
                  [
                    _vm._v(" 总课次： "),
                    _c("font", {
                      domProps: {
                        textContent: _vm._s(_vm.courseDetails.unitCount),
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  [
                    _vm._v(" 总任务数： "),
                    _c("font", {
                      domProps: {
                        textContent: _vm._s(_vm.courseDetails.taskCount),
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.dataList.length === 1
                ? _c(
                    "div",
                    _vm._l(_vm.taskData, function (item, index) {
                      return _c(
                        "el-collapse",
                        {
                          key: index,
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "right",
                                width: "250",
                                trigger: "click",
                              },
                            },
                            [
                              _c("div", [_vm._v("请选择需要查看的补课视频")]),
                              _c(
                                "ul",
                                {
                                  staticStyle: {
                                    height: "300px",
                                    "margin-top": "10px",
                                    "overflow-y": "auto",
                                  },
                                },
                                _vm._l(
                                  item.attachmentUserList,
                                  function (value, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "clearfix lesson-item",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fl",
                                            staticStyle: {
                                              "line-height": "29px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(value.attachmentTitle)
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "fr",
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.watchVideo(value)
                                              },
                                            },
                                          },
                                          [_vm._v("查看视频 ")]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                              item.attachmentUserList.length
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "lesson-address",
                                      attrs: {
                                        slot: "reference",
                                        href: "javascript:void(0)",
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      _vm._v(" 观看补课视频 "),
                                      _c("span", {
                                        staticClass: "iconfont icon-bofang",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-collapse-item",
                            {
                              attrs: {
                                title: `第${index + 1}课：${item.title}`,
                                name: index,
                              },
                            },
                            _vm._l(item.taskList, function (activity, index) {
                              return _c(
                                "ul",
                                { key: index, staticClass: "task-list" },
                                [
                                  activity.status === "已启用"
                                    ? [
                                        _c(
                                          "li",
                                          { staticClass: "task-item" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "task-tit",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.previewTask(
                                                      activity
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "task-dy" },
                                                  [
                                                    _vm._v(
                                                      "任务" + _vm._s(index + 1)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(activity.title) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            !activity.startTime
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "task-right",
                                                    staticStyle: {
                                                      color: "#c2c2c2",
                                                    },
                                                  },
                                                  [_vm._v("未学习")]
                                                )
                                              : activity.startTime &&
                                                !activity.finishTime
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "task-right",
                                                    staticStyle: {
                                                      color: "#f5c319",
                                                    },
                                                  },
                                                  [_vm._v("已学习")]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass: "task-right",
                                                    staticStyle: {
                                                      color: "#90dc61",
                                                    },
                                                  },
                                                  [_vm._v("已完成")]
                                                ),
                                            activity.videoAttachments.length !=
                                            0
                                              ? _c(
                                                  "el-popover",
                                                  {
                                                    staticClass:
                                                      "task-right task-cursor",
                                                    attrs: {
                                                      placement: "left",
                                                      trigger: "click",
                                                      width: "238",
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        "请选择需要查看的补课视频"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticClass:
                                                          "stuList clearfix",
                                                      },
                                                      _vm._l(
                                                        activity.videoAttachments,
                                                        function (
                                                          value,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "li",
                                                            { key: index },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "stupreview-tit",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      value.title
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  staticClass:
                                                                    "stu-switch",
                                                                  attrs: {
                                                                    size: "small",
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.previewVideo(
                                                                          value
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "查看视频 "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          slot: "reference",
                                                          plain: "",
                                                          size: "small",
                                                          type: "primary",
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [_vm._v("补课视频 ")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c("li", { staticClass: "task-item" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "task-tit",
                                              staticStyle: {
                                                cursor: "default",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "task-dy" },
                                                [
                                                  _vm._v(
                                                    "任务" + _vm._s(index + 1)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(activity.title) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "task-right",
                                              staticStyle: { color: "#c2c2c2" },
                                            },
                                            [_vm._v("敬请期待")]
                                          ),
                                        ]),
                                      ],
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c(
                    "div",
                    _vm._l(_vm.dataList, function (value, index) {
                      return _c(
                        "el-collapse",
                        {
                          key: index,
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            [
                              _c("template", { slot: "title" }, [
                                value.title === ""
                                  ? _c(
                                      "span",
                                      { staticStyle: { "font-size": "16px" } },
                                      [_vm._v("默认分组")]
                                    )
                                  : _c(
                                      "span",
                                      { staticStyle: { "font-size": "16px" } },
                                      [_vm._v(_vm._s(value.title))]
                                    ),
                              ]),
                              _vm._l(value.data, function (item, index) {
                                return _c(
                                  "el-collapse",
                                  {
                                    key: index,
                                    staticStyle: { "margin-left": "30px" },
                                    model: {
                                      value: _vm.activeName,
                                      callback: function ($$v) {
                                        _vm.activeName = $$v
                                      },
                                      expression: "activeName",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "right",
                                          width: "250",
                                          trigger: "click",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v("请选择需要查看的补课视频"),
                                        ]),
                                        _c(
                                          "ul",
                                          {
                                            staticStyle: {
                                              height: "300px",
                                              "margin-top": "10px",
                                              "overflow-y": "auto",
                                            },
                                          },
                                          _vm._l(
                                            item.attachmentUserList,
                                            function (value, index) {
                                              return _c(
                                                "li",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "clearfix lesson-item",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fl",
                                                      staticStyle: {
                                                        "line-height": "29px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          value.attachmentTitle
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "fr",
                                                      attrs: {
                                                        size: "mini",
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.watchVideo(
                                                            value
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("查看视频 ")]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        item.attachmentUserList.length
                                          ? _c(
                                              "a",
                                              {
                                                staticClass: "lesson-address",
                                                attrs: {
                                                  slot: "reference",
                                                  href: "javascript:void(0)",
                                                },
                                                slot: "reference",
                                              },
                                              [
                                                _vm._v(" 观看补课视频 "),
                                                _c("span", {
                                                  staticClass:
                                                    "iconfont icon-bofang",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "el-collapse-item",
                                      [
                                        _c("template", { slot: "title" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "16px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "第" + _vm._s(index + 1) + "课"
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.title))]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._l(
                                          item.taskList,
                                          function (activity, index) {
                                            return _c(
                                              "ul",
                                              {
                                                key: index,
                                                staticClass: "task-list",
                                              },
                                              [
                                                activity.status === "已启用"
                                                  ? [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "task-item",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "task-tit",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.previewTask(
                                                                      activity
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "task-dy",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "任务" +
                                                                      _vm._s(
                                                                        index +
                                                                          1
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    activity.title
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          !activity.startTime
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "task-right",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#c2c2c2",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "未学习"
                                                                  ),
                                                                ]
                                                              )
                                                            : activity.startTime &&
                                                              !activity.finishTime
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "task-right",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#f5c319",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "已学习"
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "task-right",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#90dc61",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "已完成"
                                                                  ),
                                                                ]
                                                              ),
                                                          activity
                                                            .videoAttachments
                                                            .length != 0
                                                            ? _c(
                                                                "el-popover",
                                                                {
                                                                  staticClass:
                                                                    "task-right task-cursor",
                                                                  attrs: {
                                                                    placement:
                                                                      "left",
                                                                    trigger:
                                                                      "click",
                                                                    width:
                                                                      "238",
                                                                  },
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      "请选择需要查看的补课视频"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "ul",
                                                                    {
                                                                      staticClass:
                                                                        "stuList clearfix",
                                                                    },
                                                                    _vm._l(
                                                                      activity.videoAttachments,
                                                                      function (
                                                                        value,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "li",
                                                                          {
                                                                            key: index,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "stupreview-tit",
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-size":
                                                                                      "14px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    value.title
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "el-button",
                                                                              {
                                                                                staticClass:
                                                                                  "stu-switch",
                                                                                attrs:
                                                                                  {
                                                                                    size: "small",
                                                                                    type: "primary",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      $event.stopPropagation()
                                                                                      return _vm.previewVideo(
                                                                                        value
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "查看视频 "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-left":
                                                                            "10px",
                                                                        },
                                                                      attrs: {
                                                                        slot: "reference",
                                                                        plain:
                                                                          "",
                                                                        size: "small",
                                                                        type: "primary",
                                                                      },
                                                                      slot: "reference",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "补课视频 "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : [
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "task-item",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "task-tit",
                                                              staticStyle: {
                                                                cursor:
                                                                  "default",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "task-dy",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "任务" +
                                                                      _vm._s(
                                                                        index +
                                                                          1
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    activity.title
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "task-right",
                                                              staticStyle: {
                                                                color:
                                                                  "#c2c2c2",
                                                              },
                                                            },
                                                            [_vm._v("敬请期待")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c("join-live", {
        attrs: { show: _vm.dialogLive, liveId: _vm.liveId },
        on: {
          close: function ($event) {
            _vm.dialogLive = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }